.card {
  box-shadow: "0px 100px 80px rgba(0, 0, 0, 0.16), 0px 46.233px 36.9864px rgba(0, 0, 0, 0.118668), 0px 26.4535px 21.1628px rgba(0, 0, 0, 0.100285), 0px 16.0571px 12.8457px rgba(0, 0, 0, 0.0863917), 0px 9.67509px 7.74008px rgba(0, 0, 0, 0.0736083), 0px 5.38772px 4.31018px rgba(0, 0, 0, 0.059715), 0px 2.31722px 1.85378px rgba(0, 0, 0, 0.0413319)";
  background: white;
  position: absolute;
  top: 20%;
  left: 35%;
  text-align: center;
}
@media (max-width:476px) {
  .card{
    left: 3.5%;
  }
}
@media (min-width:477px) and (max-width:750px){
  .card{
    left: 7%;
  }
}