.hero-image {
  background-image: url(../../assests/headerbg.png);
  height: 35rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.res {
  background-image: url(../../assests/restaurant.png);
  height: 29rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.res-text {
  position: absolute;
}
.hero-text {
  position: absolute;
  top: 18%;
}
.card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 0.5rem;
  width: 10rem;
  border-radius: 10px;
  min-height: 9.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 541px) {
  .hero-text {
    top: 9%;
  }
  .res {
    height: 64rem;
  }
  .card {
    width: 8rem;
  }
  p {
    font-size: 0.75rem !important;
  }
  .hero-image {
    height: 32rem;
  }
}

@media (min-width: 542px) and (max-width: 767px) {
  .hero-text {
    top: 10%;
  }
  .res {
    height: 51rem;
  }
  .card {
    width: 8rem;
  }
}
@media (min-width: 770px) and (max-width: 912px) {
  .hero-text {
    top: 16%;
  }
  p{
    font-size: x-small;
  }
  .hero-image {
    height: 24rem;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .hero-text {
    top: 16%;
  }
  .res {
    height: 55rem;
  }
  .hero-image {
    height: 24rem;
  }
  p {
    font-size: x-small !important;
  }
}
@media (min-width: 992px) and (max-width: 1075px) {
  .res {
    height: 40rem;
  }
  p {
    font-size: small !important;
  }
  .hero-image {
    height: 27rem;
  }
}
@media (min-width: 920px) and (max-width: 1180px) {
  .hero-text {
    top: 15%;
  }
  p {
    font-size: small !important;
  }
}
