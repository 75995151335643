.card {
  border-radius: 15px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.head {
  background: #d94d0d;
  color: white;
  border-radius: 15px 15px 0px 0px;
  text-align: center;
  font-weight: bold;
}
